<template>
  <div class=" mx-auto">
    <div class="">
      <Loading :isLoading="this.isLoading" />
      <div>
        <form
          @submit="Next"
          class="px-2 md:px-8 pt-6 pb-8 mb-4"
        >
          <br />
          <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
            <div class="row">
              <center>
                <p
                  class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl"
                  style="color: #82385b; font-weight: bold; margin-top: 0px"
                >
                  Información Económica
                </p>
              </center>
            </div>
            <h4 class="text-base lg:text-base xl:text-base mt-5 text-center">Paso 3 de 10</h4>
          </div>
          <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
            <div class="row">
              <center>
                <!-- <p v-if="errors.length">
                                <ul>
                                    <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                                </ul>
                                </p> -->
                <br />
              </center>
            </div>
          </div>
          <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
            <div
              class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2"
            >
              <div class="border">
                <div class="center">
                  
                  <div
                    class="text-left my-5 mx-2"
                    
                  >
                    <label to="input_monthly_income" class="label-input"
                      >Ingreso bruto mensual:</label
                    >
                    <div
                      class="w-full md:w-3/4 lg:w-3/6 mt-1 relative rounded-md shadow-sm"
                      style=""
                    >
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm"> $ </span>
                      </div>
                      <input
                        id="input_monthly_income"
                        name="input_monthly_income"
                        class="text-sm pl-7 pr-12 font-light shadow-md border-2 rounded-lg py-2 px-2 w-full mt-2 focus:border-blue-700 focus:border-blue-700 block w-full pl-7 pr-12 sm:text-sm rounded-md outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                        onkeypress="if(this.value.length==10) return false;return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                        v-model.lazy="monthly_income"
                        v-money3="money"
                        required
                      />
                    </div>
                  </div>
                  <div
                    class="text-left my-5 mx-2"
                    
                  >
                    <label to="input_monthly_liquidity" class="label-input"
                      >Liquidez mensual:</label
                    >
                    <div
                      class="w-full md:w-3/4 lg:w-3/6 mt-1 relative rounded-md shadow-sm"
                      style=""
                    >
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm"> $ </span>
                      </div>
                      <input
                        type="numeric"
                        class="text-sm pl-7 pr-12 font-light shadow-md border-2 rounded-lg py-2 px-2 w-full mt-2 focus:border-blue-700 focus:border-blue-700 block w-full pl-7 pr-12 sm:text-sm rounded-md outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                        id="input_monthly_liquidity"
                        name="input_monthly_liquidity"
                        onkeypress="if(this.value.length==10) return false;return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                        v-model.lazy="monthly_liquidity"
                        v-money3="money"
                        required
                      />
                    </div>
                  </div>
                  <div
                    class="text-left my-5 mx-2"
                    style="
                      text-align: left;
                      margin-top: -15px;
                      margin-top: -2px;
                      margin-right: 10px;
                      visibility: collapse;
                    "
                  >
                    <label class="label-input"
                      >Periodo de Ingresos:</label
                    >
                    <div class="flex mt-2">
                      <input
                        type="radio"
                        name="rdbterm"
                        value="Quincenal"
                        v-model="term"
                      />
                      Quincenal
                      <input
                        type="radio"
                        name="rdbterm"
                        value="Mensual"
                        v-model="term"
                        style="margin-left: 10px"
                      />
                      Mensual
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-start-1 md:row-auto">
                <div
                  class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1"
                >
                  <div
                    class="md:ml-32 md:mt-14 md:mr-10"
                  >
                    <div class="center">
                      <img
                        src="@/assets/Cuenta/icon-ahorro.svg"
                        alt="Icon_ahorro"
                        class="img-responsive mx-auto"
                        height="150px"
                        width="150px"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div style="display:flex;justify-content:center">
            <div>
              <button class="next_button" type="submit" id="" >Siguiente</button>
            
            </div>
          </div>
          <div style="display:flex;justify-content:center; margin-top:5px">
            <div>
              <button class="back_button" v-on:click="goToBack()" type="button" id="" >Atrás</button>
            </div>
          </div>

          <!-- <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
            <div class="row">
              <div class="col col-sm-6 mt-8">
                <center>
                  <button class="" onclick="window.location.href='/cuenta/registro-datos-personales-dos'" type="button" id="button_popups" >Regresar</button>
                </center>
              </div>
              <div class="col col-sm-6 mt-8">
                <center>
                  <button
                    class=""
                    type="submit, text-decoration: none; width:150px"
                    id="button_popups"
                  >
                    Siguiente
                  </button>
                </center>
              </div>
            </div>
          </div> -->

          <br />
        </form>
      </div>
    </div>
    <!-- <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
    />
    <link
      href="../../node_modules/tailwindcss/dist/tailwind.min.css"
      rel="stylesheet"
    /> -->
  </div>
</template>


<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import { saveRequest,getAdmissionRequest,updateOnlyStatus } from "@/api/user";
import {Money3Directive} from 'v-money3'
import {  getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      personId: null,
      rfc: "",
      member_id: "",
      term: "",
      monthly_income: "",
      monthly_liquidity: "",
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      user_id:null,
      admission_request_id:null,
      admission_status:null,
      mask: null,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      clientClassification: "",
    };
  },
  name: "Informacion-Economica",
  directives: {money3: Money3Directive},
  components: {
    MenuLateral,
    Loading,
  },
  props: {
    msg: String,
  },
  async mounted() {},
  async beforeMount() {
    await this.loadInfo();
  },
  methods: {
    changeMask(){
      this.mask ='###,###';
    },
    convertToNumber(value) {
      return Number(value.replace(/[^0-9.-]+/g, "")).toString();
    },
    formatMoney(value) {
      try {
        const options2 = { style: "currency", currency: "MXN" };
        const formatter = new Intl.NumberFormat("en-US", options2);
        value = value.toString();

        var resultado = Number(value.replace(/[^0-9.-]+/g, "")).toString();

        if (!value.includes(".")) {
          resultado = value + ".00";
        }
        if (!resultado.includes(",")) {
          resultado = resultado.replace(",", ""); //formatter.format(value)
        }
        resultado = formatter.format(resultado);

        resultado = resultado.replace("MX$", "");

        if (resultado == "NaN" || resultado < 0) {
          resultado = "";
        }

        return resultado;
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    getJsonFromLocalStorageBy(user_id) {
      let _json = JSON.parse(sessionStorage.getItem(user_id));
      if (_json == null) {
        _json = {};
      }
      return _json;
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.personId = auth.obj.person["id"];
      this.user_id = auth["id"];

      await this.searchPersonalData();
    },
    async searchPersonalData() {
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      if(Object.entries(response).length != 0)
      {
        await this.getDataAdmissionUser(response);
      }else {
        await this.getAdmissionRequest();
      }
    },
    async getDataAdmissionUser(response){
      if(Object.entries(response).length != 0) {
        this.admission_request_id = response.data.response.admission_request.id;
        this.admission_status = response.data.response.admission_request.status;

        console.log(response.data.response.admission_request.admission_information.client_classification)
        console.log(response.data.response.admission_request.admission_information.salary_info_attributes.monthly_income)
        console.log(response.data.response.admission_request.admission_information.salary_info_attributes.liquidity)

        this.clientClassification = response.data.response.admission_request.admission_information.client_classification;
        this.monthly_income = this.formatMoney(response.data.response.admission_request.admission_information.salary_info_attributes.monthly_income);
        this.monthly_liquidity = this.formatMoney(response.data.response.admission_request.admission_information.salary_info_attributes.liquidity);
      }
    },
    async Next(e) {
      e.preventDefault();
      this.isLoading = true;
      
      var monthlyIncome = this.getMonthlyIncome();
      var monthlyLiquidity = this.getMonthlyLiquidity();

      console.log("monthlyIncome: ",monthlyIncome)
      console.log("monthlyLiquidity: ",monthlyLiquidity);

      if(monthlyIncome == 0 || monthlyLiquidity == 0){
        this.errors = [];
        this.errors.push(
            "El ingreso mensual bruto y liquidez mensual, no puede ser 0.00."
        );
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false;
        return;
      }
      if (monthlyLiquidity > monthlyIncome) {
        this.errors = [];
        this.errors.push(
          "Liquidez mensual no puede ser mayor que ingreso bruto"
        );
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.isLoading = false;
        return;
      }

      var infoJson = this.buildJson();

      if(this.admissionStatusIsValidToSave(this.admission_status))
      {
        await this.saveRequest(infoJson);
      }
      this.isLoading = false;

      //if (this.clientClassification == "educator") {
      // window.location.href = "/cuenta/informacion-laboral";
      //} else if (this.clientClassification == "cabsa_employee") {
      //  window.location.href = "/cuenta/informacion-laboral-miembros";
      //}
      // window.location.href = "/cuenta/informacion-laboral-cliente-regular";
      this.$router.push({path:"/cuenta/informacion-laboral-cliente-regular"});
    },
    getMonthlyIncome(){
      var monthlyIncome = parseFloat(
        this.monthly_income.includes(",")
          ? this.monthly_income.replace(",", "")
          : this.monthly_income
      );
      return monthlyIncome;
    },
    getMonthlyLiquidity(){
      var monthlyLiquidity = parseFloat(
        this.monthly_liquidity.includes(",")
          ? this.monthly_liquidity.replace(",", "")
          : this.monthly_liquidity
      );
      return monthlyLiquidity;
    },
    buildJson(admission_status = "economical_info") {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);

      json.data.response.admission_request.admission_information.salary_info_attributes.monthly_income = this.getMonthlyIncome();
      json.data.response.admission_request.admission_information.salary_info_attributes.liquidity = this.getMonthlyLiquidity();
      json.data.response.admission_request.admission_information.salary_info_attributes.income_frecuency = "mensual";

      this.setPersonalInfoToLocalStorage('admissionUser'+this.user_id, json)

      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_request_id,
        user_id: this.user_id,
        change_for: admission_status,
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    async getAdmissionRequest(){
      await getAdmissionRequest(
        sessionStorage.getItem("login"),
        this.personId
      )
      .then((response) => {
        this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        this.getDataAdmissionUser(response)
      } )
      .catch((error) => {
        Swal.fire({
          title: "Aviso",
          text:
            "Se presentó un error. No fue posible obtener los datos de la solicitud.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        this.$router.push({path:"/cuenta/registro-datos-personales"});
      });
    },
    async saveRequest(jsonData){
      this.isLoading=true;
      jsonData.token_auth = sessionStorage.getItem("login");
      await saveRequest(
        jsonData
      )
        .then((response ) => {
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
          console.log("Solicitud guardada con éxito");
        })
        .catch((error) => {
          console.log(
            "Se presento un error al guardar la información personal: ",
            error
          );
        }).finally( () => {this.isLoading=false})
    },
    async goToBack() {
      await updateOnlyStatus(sessionStorage.getItem("login"), this.admission_request_id, "update_personal_info").then(() => {
        this.$router.push({path:"/cuenta/registro-datos-personales-dos"});
      });
    },
    admissionStatusIsValidToSave( status ) {
      const validStatuses = [
        "autorizo_descuento_aceptado",
        "video_completo",
        "asignada",
        "en_revision",
        "rechazada",
        "cancelada"
      ];

      return (validStatuses.find(value => value == status))? false: true;
    }
  },
};
</script>

<style>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
  .border {
    border-right: 1px dashed #333;
    border-top: 1px dashed #ffffff;
    border-left: 1px dashed #ffffff;
    border-bottom: 1px dashed #ffffff;
  }
}

@media (max-width: 766px) {
  .border {
    margin-left: 9px;
    margin-right: -15px;
    border-right: 1px dashed #ffffff;
    border-top: 1px dashed #ffffff;
    border-left: 1px dashed #ffffff;
    border-bottom: 1px dashed #ffffff;
  }
}

#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}
</style>